import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/orderDetail'
  },
  {
    path: '/orderDetail',
    name: 'orderDetail',
    component: () => import('@/views/index'),
    meta: {
      title: '订单详情'
    }
  },
  {
    path: '/audit',
    name: 'audit',
    component: () => import('@/views/audit'),
    meta: {
      title: "审核详情"
    }
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

const defaultTitle = '订单详情'
router.beforeEach((to, from, next) => {
  console.log("路由", to.path, from.path)
 document.title = to.meta.title ? to.meta.title : defaultTitle
 next()
})
export default router
