import axios from '@/utils/request.js'
import { httpUrl } from '@/utils/config.js'

const request = {
    // 获取订单详情
	getOrderDetail(id) {
		return axios.post(`${httpUrl}/order/orderDetail`, id)
    },
    // 根据id查询围栏信息
	getRailByIds(ids) {
		return axios.post(`${httpUrl}/rail/selectByRailIds`, ids)
    },
    // 订单轨迹查询
	getOrderGps(carId, url) {
		return axios.get(`https://hl.ah-zk.cn/deviceData/hailuo/data2/${carId}?${url}`, )
    },
    // 异常订单详情
	warningOrderDetail(orderId) {
		return axios.post(`${httpUrl}/order/listExceptLog`, orderId);
    },
    // 获取订单详情图片
	getOrderDetailPhoto(id) {
		return axios.post(`${httpUrl}/order/listOrderPic`, id)
	},
	/* 三级审核订单 */
	examineOrder(examineForm) {
		return axios.post(`${httpUrl}/order/checkOrder`, examineForm);
	},
	/* 订单文件上传 */
	uploadOrderFile(uploadForm) {
		return axios.post(`${httpUrl}/order/uploadCheckFile`, uploadForm);
	},
}

export default request