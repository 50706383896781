import { MessageBox } from 'element-ui';
import sha1 from 'js-sha1';
import { uploadUrl } from '@/utils/config';
import store from '@/store';


const menuDataFormat = (index = 0, list, parentId) => {
  let subMenu = [];
  if (parentId) {
    subMenu = list.filter((item) => {
      if (item.parentId) {
        return item.parentId == parentId;
      }
      return item.pId == parentId;
    });
  } else {
    subMenu = list.filter((item) => {
      if (item.parentId) {
        return item.parentId == index;
      }
      return item.pId == index;
    });
  }
  subMenu.forEach((item) => {
    const children = menuDataFormat('0', list, item.id);
    if (children.length) {
      item.children = children;
    }
  });
  return subMenu;
};

const removeProperty = function (object) {
  for (const prop in object) {
    if (object[prop] === '' || object[prop] === null) {
      delete object[prop];
    }
  }
  return object;
};


const deleteConfirm = async function () {
  const confirmResult = await MessageBox.confirm(
    '此操作将永久删除, 是否继续?',
    '提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    },
  ).catch(err => err);
  return confirmResult;
};


/**
 * 文件上传
 * @param {file} file 文件
 */
const uploadPhoto = function (file, orderInfo) {
  const reader = new FileReader();
  const p = new Promise((ok, error) => {
    reader.addEventListener('load', () => {
      const data = new FormData();
      data.append('file', file);
      if(orderInfo){
        data.append('chuchangriqi',orderInfo.chuchangriqi?orderInfo.chuchangriqi:orderInfo.chumenriqi);
        data.append('officeId', orderInfo.officeId);
        data.append('fahuodanhao', orderInfo.fahuodanhao?orderInfo.fahuodanhao:orderInfo.gonghuodanhao);
      }
      fetch(`${uploadUrl}/uploadFile`, {
        method: 'POST',
        body: data
      })
      .then(res => res.json())
      .then(json => {
        console.log("文件上传结果",json.bizContent)
        ok(json)
      })
      .catch(err => error(err))
    });
  });
  reader.readAsArrayBuffer(file);
  return p;
};

/* 权限按钮判断 */
const authButton = function (id) {
  const { menuList } = store.state.menu;
  let flag = false;
  for (let i = 0; i < menuList.length; i++) {
    if (menuList[i].id == id) {
      flag = true;
      break;
    }
  }
  return flag;
};


const validateCarNumber = function (rule, value, callback) {
  const express = /^[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z]{1}[A-Z]{1}[A-Z0-9]{5,6}$/;
  if (value === '' || !value) {
    callback(new Error('车牌号不能为空 '));
  } else if (
    !express.test(value)
  ) {
    callback(
      new Error(
        '请输入正确车牌号',
      ),
    );
  } else {
    callback();
  }
};

/* 设置cookie */
const setCookie =  function(tokenName,rememberUserToken, exseconds) {
  const exdate = new Date(); // 获取时间
  exdate.setTime(exdate.getTime() +  1000 * exseconds); // 保存的天数
  // 字符串拼接cookie
  window.document.cookie = `${tokenName}=${rememberUserToken};path=/;expires=${exdate.toGMTString()}`;
};

/* 读取cookie */
const getCookie = function (name) {
  let arr;
  const reg = new RegExp(`(^| )${name}=([^;]*)(;|$)`);
  if (arr = document.cookie.match(reg)) { return unescape(arr[2]); }
  return null;
};

const timeTransform = function(timeoutDuration){
  if(timeoutDuration < 60 ){
    return `停留${timeoutDuration}秒`
  }else if (timeoutDuration >60 && timeoutDuration < 60 * 60){
    const MINUTES =  Math.floor(timeoutDuration/(60) )
    const SECONDS = timeoutDuration % 60
    return `停留${MINUTES}分${SECONDS}秒`
  }else if (timeoutDuration < 60 * 60 * 24 && timeoutDuration > 60 * 60){
    const HOURS = Math.floor(timeoutDuration/(60*60) )
    const MINUTES =  Math.floor(timeoutDuration%(60*60)/60)
    const SECONDS = timeoutDuration%(60*60)%60
    return `停留${HOURS}时${MINUTES}分${SECONDS}秒`
  }else {
    const DAYS = Math.floor(timeoutDuration/(60 * 60 * 24))
    const HOURS = Math.floor((timeoutDuration%(60 * 60 * 24))/(60*60))
    const MINUTES =  Math.floor((timeoutDuration%(60 * 60 * 24))%(60*60)/60)
    const SECONDS = timeoutDuration%(60*60*24)%60
    return `停留${DAYS}天${HOURS}时${MINUTES}分${SECONDS}秒`
  }
};
/* 到期时间水泥样式判断 */
const timeCssCementCheck = function(platformEndDate, chuchangriqi, isWaring) {
  if (!platformEndDate) {
    if (isWaring == 0) {
      return 'isNormal'
    } else {
      return 'isUnusual'
    }
  } else {
    let endDate, chuchang
    if (typeof (platformEndDate) == 'string' && typeof (chuchangriqi) == 'string') {
      endDate = platformEndDate.replace(/-/g, '/');
      chuchang = chuchangriqi.replace(/-/g, '/');
    } else {
      endDate = platformEndDate
      chuchang = chuchangriqi
    }
    if (Date.parse(new Date(endDate)) > Date.parse(new Date(chuchang))) {
      if (isWaring == 0) {
        return 'isNormal'
      } else {
        return 'isUnusual'
      }
    } else {
      return 'isUnusual'
    }
  }
};
/* 到期文字判断 */
const timeTextCementCheck = function (platformEndDate, chuchangriqi, isWaring) {
  if (!platformEndDate) {
    if (isWaring == 0) {
      return '通过'
    } else {
      return '不通过'
    }
  } else {
    let endDate = platformEndDate.replace(/-/g, '/');
    let chuchang = chuchangriqi.replace(/-/g, '/');
    if (Date.parse(new Date(endDate)) > Date.parse(new Date(chuchang))) {
      if (isWaring == 0) {
        return '通过'
      } else {
        return '不通过'
      }
    } else {
      return '到期'
    }
  }
};

export {
  menuDataFormat, removeProperty, deleteConfirm, uploadPhoto, authButton, validateCarNumber,setCookie,getCookie,timeTransform,
  timeCssCementCheck,timeTextCementCheck
};
