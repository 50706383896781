const auth = {
    username: 'hailuo',
    password: 'hailuo',
  };
  const otherAuth = {
    username: 'guest',
    password: 'hailuo_guest',
  };
  const otherLoginForm = {
    grant_type: 'client_credentials',
  };
  // const httpUrl = 'http://192.168.0.105:8091/api';
  // const restUrl = 'http://192.168.0.105:8091/rest';
  // const authUrl = 'http://192.168.0.105:8089';
  // const httpUrl = 'http://192.168.0.239:9724/api';
  // const restUrl = 'http://192.168.0.239:9724/rest';
  // const authUrl = 'http://192.168.0.239:10703';
  
  const httpUrl = 'https://api.hailuo.ah-zk.com/api';
  const restUrl = 'https://api.hailuo.ah-zk.com/rest';
  const authUrl = 'https://auth.hailuo.ah-zk.com';
  
  const uploadUrl = 'https://fs.hailuo.ah-zk.com';
  const imgUrl = 'https://img.hailuo.ah-zk.com';
  
  export {
    auth, httpUrl, restUrl, authUrl, imgUrl, uploadUrl, otherAuth, otherLoginForm,
  };
  