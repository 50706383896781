import qs from 'qs';
import axios from 'axios';
import { Message } from 'element-ui';
import router from '../router';
import store from '../store/index';
import { removeProperty,getCookie } from './tool.js';
import moment from 'moment';

axios.defaults.timeout = 29000;

axios.interceptors.request.use(
  (config) => {
    if (config.method === 'post' && !config.url.includes('checkOrders') && !config.url.includes('postWeiLiu')) {
      config.transformRequest = [function (data) {
        data = qs.stringify(removeProperty(data));
        return data;
      }];
    }
    if (!config.url.includes('/oauth/token')) {
      const TOKEN = getCookie('remember_user_token')
      config.headers.Authorization = TOKEN
    }
    return config;
  },
  error => Promise.reject(error),
);

axios.interceptors.response.use(
  (response) => {
    // 对响应数据做点什么
    const res = response.data;
    if (!res.errorCode || res.errorCode == '0') {
      return Promise.resolve(response);
    } if (res.errorCode == '401') {
      Message.error({
        message: '授权超时,请重新登录',
        offset: 140,
      });
      Message.close = () => {
        router.push('/login');
        return Promise.reject(res);
      };
    }else if(res.errorCode == '999'){
      if(!localStorage.getItem('errData')){
        const errData = {
          count: 1,
          errTime: moment().format('YYYY-MM-DD HH:mm:ss')
        }
        localStorage.setItem('errData', JSON.stringify(errData));
      }else{
        const errData =  JSON.parse(localStorage.getItem('errData'));
        if(errData.count >= 3){
          Message.error({
            message: '频繁请求超时,请重新登录',
            offset: 140,
          });
          Message.close = () => {
            router.push('/login');
            return Promise.reject(res);
          };
        }else{
          if(moment().diff(moment(errData.errTime), 'minutes')>2){
            errData.errTime = moment().format('YYYY-MM-DD HH:mm:ss');
            errData.count = 1;
            localStorage.setItem('errData', JSON.stringify(errData));
          }else{
            errData.count = errData.count + 1;
            localStorage.setItem('errData', JSON.stringify(errData));
          }
          Message.error({
            message: '请求超时，请重试',
            offset: 140,
          });
          return Promise.reject(res);
        }
      }
    } else if (res.Message == null && res.errorMessage == null) {
      Message.error({
        message: `未知错误 请稍后再试  错误码:${res.errorCode}`,
        offset: 140,
      });
      return Promise.reject(res);
    } else {
      const specialURL = "https://api.hailuo.ah-zk.com/api/order/listOrderGps";
      if(response.request.responseURL == specialURL){
        return Promise.resolve(response);
      }else{
        Message.error({
          message: res.errorMessage.substring(0, 1400),
          offset: 140,
        });
        return Promise.reject(res);
      }
    }
  },
  (err) => {
    if (err && err.response) {
      switch (err.response.status) {
        case 400:
          err.message = '请求错误(400)';
          break;
        case 401:
          err.message = '授权超时,请重新登录(401)';
          Message.close = () => {
            router.push('/login');
            return Promise.reject(res);
          };
          break;
        case 403:
          return Promise.reject(err.response.data);
        case 404:
          err.message = '请求地址不存在(404)';
          break;
        case 408:
          err.message = '请求超时(408)';
          break;
        default:
          err.message = err.response.data.message || err.response.data.error_description;
      }
    } else {
      err.message = '连接服务器失败!';
    }
    Message.error({
      message: err.message,
      offset: 140,
    });
    return Promise.reject(err.response.data);
  },
);

export default axios;
