import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './utils/elementUI';
import api from './api';
import { imgUrl, uploadUrl } from './utils/config';
import { ImagePreview, Uploader } from 'vant';
import 'vant/lib/index.css';
import { timeTextCementCheck, timeCssCementCheck } from './utils/tool';


Vue.use(ImagePreview);
Vue.use(Uploader);

Vue.config.productionTip = false

Vue.prototype.$http = api;
Vue.prototype.imgUrl = imgUrl;
Vue.prototype.uploadUrl = uploadUrl;
Vue.prototype.timeTextCementCheck = timeTextCementCheck;
Vue.prototype.timeCssCementCheck = timeCssCementCheck;

Vue.filter('checkStatusFilter', (val) => {
  switch (val) {
    case 0:
      return '未审核';
    case 1:
      return '已通过';
    case 2:
      return '不通过';
    default:
      return '未知';
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
